.carousel {
    width: 100%;
    overflow: scroll;
    padding: 0px;
    position: relative;
  }
  
  .carousel::-webkit-scrollbar {
    width: 8px;
    /* Tamaño del scroll en vertical */
    height: 8px;
    /* Tamaño del scroll en horizontal */
    display: none;
    /* Ocultar scroll */
  }
  
  .carousel::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  .carousel__container {
    margin: 16px 0px;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    padding-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            /*justify-content: center;*/
  }
  
  .carousel-item {
      margin: 12px 12px 12px 12px;
      width: 220px;
      cursor: pointer;
      -webkit-transition: 450ms all;
      transition: 450ms all;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      position: relative;
  }
  
  .carousel-item:hover ~ .carousel-item {
    -webkit-transform: translate3d(100px 0px 0px);
            transform: translate3d(100px 0px 0px);
  }
  
  
  .carousel__container:hover .carousel-item {
    opacity: 1;
  }
  
  .carousel-item:hover ~ .carousel-item {
    opacity: 0.3;
  }
  
  .carousel__container:hover .carousel-item:hover {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
    opacity: 1;
  }
  
  .carousel-item img {
    width: 210px;
    height: 250px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .carousel-item__details {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.9)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    font-size: 10px;
    opacity: 0;
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    padding: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  
  .carousel-item__details p {
    margin: 0px;
  }
  
  .carousel-item__details:hover .carousel-item__details {
    opacity: 1;
  }
  
  .carousel-item:hover .carousel-item__details {
    opacity: 1;
  }
  
  .carousel-item__details img {
    width: 15px;
    height: 15px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .carousel-item__details--title {
    color: white;
    font-size: 13px;
  }
  
  .carousel-item__details--subtitle {
    color: white;
    font-size: 10px;
  }
  