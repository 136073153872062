body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

:root {
  --blue-color: rgb(4,214,255);
  --purple-color: rgb(120,60,189);
  --darkPurple-color:rgb(60,16,83);
  --darkGray-color:rgb(136,136,136);
  --gray-color:rgb(200,201,199);
  --white-color:rgb(255,255,255);
  --title-size-text:18px;
  --normal-size-text:14px;
}

.classFormModal{
  width: 95% !important;
  margin-top: 56px;
}

.border{
  border: solid;
}

@media (max-width: 800px) {
  :root {
    --title-size-text:22px;
    --normal-size-text:18px;
  }
}

.inputFile{
  color: transparent;
}