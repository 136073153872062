.circle {
    width: 120px;
    height: 120px;
    border: 8px solid #B9B3BC; /* Borde grueso de color azul */
    border-radius: 50%; /* Hace que el borde sea un círculo */
    position: relative; /* Para que los elementos internos se posicionen correctamente */
    overflow: hidden; /* Evita que la imagen salga del círculo */
    cursor: pointer;
    z-index: 100;
    
}

.circleImage {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el texto en el círculo */
}

.circle .text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el texto en el círculo */
    color: black; /* Color del texto */
    text-align: center;
}