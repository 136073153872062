
.item-premium {
    color: #fffffff1;
    text-decoration: none;   
    text-align: center;
}

.item-normal {
    color: #fffffff1;
    text-decoration: none;   
    text-align: center;
}

.item-premium:hover {
    color: rgb(0, 0, 0);
    font-weight: bold;  
    cursor: pointer;
}

.item-normal:hover {
    color: rgb(0, 0, 0);
    font-weight: bold;  
    cursor: pointer;
}

.panicButtom{
    background: rgb(127, 186, 0);
    border-radius: 250%;
    border: 20px solid rgba(255, 255, 255, 0.781);
    width: 300px;
    height: 300px;
    color: #fff;
    font-size: 25px;
    font-weight: 700; 
    box-shadow: 0px 0px 40px rgb(105, 105, 105);
    font-family: Bahnschrift Condensed;
}

.panicButtomTechnicians{
    background: #783CBD;
    border-radius: 250%;
    border: 20px solid rgba(255, 255, 255, 0.781);
    width: 300px;
    height: 300px;
    color: #fff;
    font-size: 25px;
    font-weight: 700; 
    box-shadow: 0px 0px 40px rgb(105, 105, 105);
    font-family: Bahnschrift Condensed;
}

.panicButtomRequest{
    background: rgb(33, 91, 51);
    border-radius: 250%;
    border: 20px solid rgba(255, 255, 255, 0.781);
    width: 300px;
    height: 300px;
    color: #fff;
    font-size: 25px;
    font-weight: 700; 
    box-shadow: 0px 0px 40px rgb(105, 105, 105);
    font-family: Bahnschrift Condensed;
}

.panicButtomRequestTechnicians{
    background: #3C1053;
    border-radius: 250%;
    border: 20px solid rgba(255, 255, 255, 0.781);
    width: 300px;
    height: 300px;
    color: #fff;
    font-size: 25px;
    font-weight: 700; 
    box-shadow: 0px 0px 40px rgb(105, 105, 105);
    font-family: Bahnschrift Condensed;
}



@media (max-width: 600px) {
    
    .panicButtom{
        font-size: 20px;
    }

    .panicButtomRequest{
        font-size: 20px;
    }

}
