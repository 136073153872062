@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  .pulse-animation {
    animation: pulse 1s infinite;
  }
  